.loader {
    border-radius: 50%;
    border-top: 12px solid #1D8DB8;
    border-bottom: 12px solid #006287;
    border-right: 12px solid #343a40;
    border-left: 12px solid #343a40;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
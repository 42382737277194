*{
    font-family: 'Raleway', 'Open Sans';
}

html,
body {
    height: 100%;
    margin: 0;
}

body {
    background: #000;
}

.auth-status-text {
    color: #a9afbbd1;
}

.fullCenter {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tc {
    text-align: center;
}

.white {
    color: #fff;
}

.google-btn {
    width: 184px;
    height: 42px;
    background-color: rgb(249,104,28,1);
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
}
    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: #fff;
    }
    .google-icon {
      position: absolute;
      margin-top: 11px;
      margin-left: 11px;
      width: 18px;
      height: 18px;
    }
    .btn-text {
      float: right;
      margin: 11px 11px 0 0;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: "Roboto";
    }
  
  @import url(https://fonts.googleapis.com/css?family=Roboto:500);